export default [
  {
    path: '/cursos',
    name: 'cursos',
    component: () => import('./pages/course/Courses.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Cursos',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/cursos/:course_slug/aula',
    name: 'aula',
    component: () => import('./pages/course/Player.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Player',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/cursos/:course_slug/aula/g',
    name: 'aula_multi',
    component: () => import('./pages/course/PlayerAlternative.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Player',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/curso/:course_slug',
    name: 'buyCourse',
    component: () => import('./pages/course/CourseDetails.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Comprar',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/gravar/curso',
    name: 'screenRecorder',
    component: () => import('./pages/course/ScreenRecorder.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Gravação',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/cursos/aula/gravar',
    name: 'aula_gravar',
    component: () => import('./pages/course/Capture2.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Player',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
]
