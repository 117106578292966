const array = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../../views/Login.vue'),
    meta: {
      layout: 'full',
      pageTitle: 'Login',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../../views/Register.vue'),
    meta: {
      layout: 'full',
      guest: true,
    },
  },
  {
    path: '/forget-password',
    name: 'forgetPassword',
    component: () => import('../../views/ForgetPassword.vue'),
    meta: {
      layout: 'full',
      guest: true,
    },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import('../../views/ResetPassword.vue'),
    meta: {
      layout: 'full',
      guest: true,
    },
  },
]

export default array
