import * as types from './mutation-types'

export default {
  [types.SET_USER_STATS](state, payload) {
    state.userStats = payload
  },
  [types.SET_RANKING](state, payload) {
    state.ranking = payload
  },
}
