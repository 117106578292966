const array = [
  {
    path: '/associado/combo/pagamento',
    name: 'paymentAssociadoCombo',
    component: () => import('./pages/paymentCombo/Cart.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Pedidos',
    },
  },
  {
    path: '/associado/combo/finalizar-pagamento',
    name: 'finishPaymentAssociadoCombo',
    component: () => import('./pages/paymentCombo/InstallmentsAndFinish.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Finalizar pedido',
    },
  },
  {
    path: '/associados',
    name: 'associatesdyear',
    component: () => import('./pages/ListCurrentYear.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Lista de Associados 2022',
    },
  },
]
export default array
