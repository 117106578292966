import store from '@/store'

const { congressAll } = store.state.congress

const array = [
  {
    path: '/congressos',
    name: 'congress_home',
    component: () => import('./pages/ListOfCongresses.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Congressos',
      breadcrumb: [
        {
          text: 'Congressos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:congress/trabalhos-cientificos',
    name: 'listOfCientificWork',
    component: () => import('./pages/ListOfScientificWorks.vue'),
    meta: {
      layout: 'congress',
      pageTitle: 'Envio de trabalhos cientificos',
      breadcrumb: [
        {
          text: 'Congressos',
          active: false,
          to: '/congressos',
        },
        {
          text: 'Trabalhos cientificos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/trabalhos-cientificos/envio/:congress/:work_id',
    name: 'submissionOfScientificWork',
    component: () => import('./pages/SubmissionOfScientificWork.vue'),
    meta: {
      layout: 'congress',
      pageTitle: 'Envio de trabalhos cientificos',
      breadcrumb: [
        {
          text: 'Trabalhos cientificos',
          active: false,
        },
        {
          text: 'Envio',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:congress/trabalhos-cientificos/envio/',
    name: 'submissionOfScientificWorkSpecific',
    component: () => import('./pages/SubmissionOfScientificWork.vue'),
    meta: {
      layout: 'congress',
      requiresAuth: true,
      pageTitle: 'Envio de trabalhos cientificos',
      breadcrumb: [
        {
          text: 'Congresso',
          active: false,
        },
        {
          text: 'Trabalhos cientificos',
          active: false,
        },
        {
          text: 'Envio',
          active: true,
        },
      ],
    },
  },
  {
    path: '/congress/:congress/:sluglive',
    name: 'congress',
    component: () => import('./pages/WatchLive.vue'),
    meta: {
      layout: 'congress',
      pageTitle: 'Assistir transmissão',
      requiresAuth: true,
      breadcrumb: [
        {
          text: 'Congresso',
          active: false,
        },
        {
          text: 'Assistir',
          active: false,
        },
      ],
    },
  },
  {
    path: '/calendario',
    name: 'calendar',
    component: () => import('./pages/calendar/Calendar.vue'),
    meta: {
      requiresAuth: true,
      layout: 'congress',
      pageTitle: 'Agendamentos',
      breadcrumb: [
        {
          text: 'Congresso',
          active: false,
        },
        {
          text: 'Atividades',
          active: false,
        },
        {
          text: 'Atividades anteriores',
          active: true,
        },
      ],
    },
  },
  {
    path: '/certificados',
    name: 'Certificate',
    component: () => import('./pages/Certificate.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Certificados',
      breadcrumb: [
        // {
        //   text: 'Congresso',
        //   active: false,
        // },
        // {
        //   text: 'Certificados',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/compartilhar-eventos',
    name: 'shareEvent',
    component: () => import('./pages/shareEvents/ShareSpeakerEvent.vue'),
    meta: {
      layout: 'congress',
      requiresAuth: true,
      pageTitle: 'Eventos',
      breadcrumb: [
        //
      ],
    },
  },
]

congressAll.forEach((element) => {
  array.push(
    {
      path: `/atividades-atuais/${element.id}/:slug`,
      name: `${element.path_event}-current`,
      component: () => import('./pages/ListOfCurrentActivities.vue'),
      meta: {
        layout: 'congress',
        requiresAuth: true,
        pageTitle: 'Lista de atividades',
        breadcrumb: [
          {
            text: element.title,
            active: false,
          },
          {
            text: 'Atividades',
            active: true,
          },
        ],
      },
    },
    {
      path: `/atividades-anteriores/${element.id}/:slug`,
      name: `${element.path_event}-previous`,
      component: () => import('./pages/ListOfPreviousActivities.vue'),
      meta: {
        requiresAuth: true,
        layout: 'congress',
        pageTitle: 'Lista de atividades anteriores',
        breadcrumb: [
          {
            text: element.title,
            active: false,
          },
          {
            text: 'Atividades',
            active: true,
          },
          {
            text: 'Atividades anteriores',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: `/todas-atividades/${element.id}/:slug`,
    //   name: `${element.path_event}-finish`,
    //   component: () => import('./pages/ListOfCurrentActivities.vue'),
    //   meta: {
    //     requiresAuth: true,
    //     layout: 'congress',
    //     pageTitle: 'Atividades',
    //     breadcrumb: [
    //       {
    //         text: element.title,
    //         active: false,
    //       },
    //       {
    //         text: 'Atividades',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    {
      path: `/todas-atividades/${element.id}/:slug`,
      name: `${element.path_event}-all`,
      component: () => import('./pages/ListOfPreviousActivitiesFinish.vue'),
      meta: {
        requiresAuth: true,
        layout: 'congress',
        pageTitle: 'Todas atividades',
        breadcrumb: [
          {
            text: element.title,
            active: false,
          },
          {
            text: 'Todas',
            active: true,
          },
        ],
      },
    }
  )

  // pertence ao modulo congressAdministrator
  array.push({
    // Adicionar um botão para essa rota no list congress
    path: '/administrador/selecionar/congressos/:congress',
    name: `${element.path_event}_dashboard_congress_administrator`,
    component: () => import('../congressAdministrator/pages/Dashboard.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Administrador do congresso',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Congressos',
          active: true,
        },
      ],
    },
  })
})

export default array
