import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async getAllCongresses(...args) {
    const resp = await axios.get(this.jwtConfig.allCongressesEndpoint, ...args)
    return resp
  }

  async getCongresses(...args) {
    const resp = await axios.get(this.jwtConfig.getCongressEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }

  // eslint-disable-next-line no-dupe-class-members
  async getEvent(...args) {
    const resp = await axios.get(this.jwtConfig.getEventEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }

  async getAllCurrentActivities(...args) {
    console.log(...args)
    const resp = await axios.get(this.jwtConfig.activiesCurrentEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }

  async getAllPreviousActivities(...args) {
    console.log(...args)
    const resp = await axios.get(this.jwtConfig.activiesPreviousEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }

  async congressAccess(...args) {
    const resp = await axios.post(this.jwtConfig.congressAccess, ...args)
    return resp
  }

  async registerUserEvent(...args) {
    const resp = await axios.post(
      this.jwtConfig.registerUserEventEndpoint,
      ...args
    )
    return resp
  }

  async unregisterUserEvent(...args) {
    const resp = await axios.post(
      this.jwtConfig.unregisterUserEventEndpoint,
      ...args
    )
    return resp
  }

  async getUserEvent(...args) {
    const resp = await axios.get(this.jwtConfig.getUserEventsEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }

  async getEventSpeakerByEvent(...args) {
    const resp = await axios.get(this.jwtConfig.getEventSpeakerEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }

  async getDeviceId(...args) {
    const resp = await axios.get(this.jwtConfig.getDeviceId, {
      params: { ...args[0] },
    })
    return resp
  }

  // Live chat
  async sendMessage(...args) {
    const resp = await axios.post(this.jwtConfig.sendMessage, ...args)
    return resp
  }

  async getLiveMessages(...args) {
    const resp = await axios.post(this.jwtConfig.getLiveMessages, ...args)
    return resp
  }

  async getLiveInfo(...args) {
    const resp = await axios.post(this.jwtConfig.getLiveInfo, ...args)
    return resp
  }

  async getEventsBySpeaker(...args) {
    const resp = await axios.get(this.jwtConfig.getEventsBySpeakerEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }

  // share card
  async getSpeaker(...args) {
    const resp = await axios.get(this.jwtConfig.getSpeakerEndpoint, ...args)
    return resp
  }

  async getEventBlock(...args) {
    const resp = await axios.get(this.jwtConfig.getEventBlockEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }

  async speakerBlocksNow(...args) {
    const resp = await axios.get(this.jwtConfig.getSpeakerBlocksNowEndpoint, {
      params: { ...args[0] },
    })
    return resp
  }
}
