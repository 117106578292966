const array = [
  {
    path: '/administrador/congressos',
    name: 'list_congress',
    component: () => import('./pages/Congress/ListCongress.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Lista de congressos',
      breadcrumb: [
        {
          text: 'Administrador',
          active: true,
        },
        {
          text: 'Congressos',
          active: false,
        },
      ],
    },
  },
  {
    // adicionar nos botões congress list
    path: '/administrador/eventos/:congress_slug/:congress_id',
    name: 'get_congress',
    component: () => import('./pages/Events/ListEvents.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Lista de eventos',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Eventos',
          active: true,
        },
      ],
    },
  },
  {
    // add nos botões /administrador/eventos/snno-2021
    path: '/administrador/congressos/ao-vivo/:slug',
    name: 'live_congress_administrator',
    component: () => import('./pages/live/LiveSpeakers.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Administrador de AO VIVO',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Congressos',
          active: true,
        },
        {
          text: 'Live',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador/congressos/atividades/:slug',
    name: 'list_congress_atividade',
    component: () => import('./pages/crudEvent/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Administrador de atividades',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Congressos',
          active: true,
        },
        {
          text: 'Atividades',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador/congressos/palestrantes/:slug',
    name: 'speaker_congress_administrator',
    component: () => import('./pages/crudSpeaker/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Administrador de palestrantes',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Congressos',
          active: true,
        },
        {
          text: 'Palestrantes',
          active: true,
        },
      ],
    },
  },
  {
    // add nos botões de congress list
    path: '/administrador/congressos/trabalhos/:slug',
    name: 'scientific_work_congress_administrator',
    component: () => import('./pages/crudScientificWork/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Administrador de trabalhos',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Congressos',
          active: true,
        },
        {
          text: 'Trabalhos',
          active: true,
        },
      ],
    },
  },
  {
    // add nos botões de congress list
    path: '/administrador/congressos/inscritos/:slug',
    name: 'registred_congress_administrator',
    component: () => import('./pages/crudRegistered/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Administrador de inscritos',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Congressos',
          active: true,
        },
        {
          text: 'Inscritos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/administrador/palestrantes',
    name: 'list_speaker',
    component: () => import('./pages/Speaker/ListSpeaker.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Lista de palestrantes',
      breadcrumb: [
        {
          text: 'Administrador',
          active: true,
        },
        {
          text: 'Usuários',
          active: false,
        },
      ],
    },
  },
  {
    path: '/administrador/congressos/compartilhar-eventos/:id',
    name: 'shareEventAdmin',
    component: () => import('./pages/shareEvents/ShareSpeakerEvent.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Eventos',
      breadcrumb: [
        //
      ],
    },
  },
]

function dynamicSlug(route) {
  return {
    congress_slug: route.params.congress_slug,
  }
}

array.push(
  {
    path: '/administrador/:congress_slug/evento/sub-atividades/:id',
    name: 'subActivities',
    component: () => import('./pages/crudSubActivities/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Sub-atividades',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Eventos',
          active: false,
          to: {
            name: 'list_events',
            params: {
              dynamicSlug,
            },
          },
        },
        {
          text: 'Sub-atividades',
          active: true,
        },
      ],
    },
  },
  {
    path: '/convite-direto',
    name: 'direct-invite',
    component: () => import('./pages/Speaker/DirectInviteSpeaker.vue'),
    meta: {
      layout: 'full',
    },
  }
)

export default array
