export default [
  {
    path: '/administrador/socios/',
    name: 'active_partners',
    component: () => import('./pages/crudActivePartners/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Administrador de Socios ativos',
      breadcrumb: [
        {
          text: 'Administrador',
          active: false,
        },
        {
          text: 'Congressos',
          active: true,
        },
        {
          text: 'Socios',
          active: true,
        },
      ],
    },
  },
]
