import useJwt from '@/modules/payment/jwt'
import * as types from './mutation-types'

// eslint-disable-next-line import/prefer-default-export
export const ActionGetOrder = async ({ dispatch }, payload) => {
  const api = await useJwt.getOrder()
  dispatch('ActionSetOrder', api.data.response.order)
  return api.data.response.order
}

export const ActionSetOrder = ({ commit }, payload) => {
  commit(types.SET_ORDER, payload)
}

// excluir depois
export const ActionSetAmount = async ({ dispatch }, payload) => {
  const api = await useJwt.setAmount()
  console.log(api)
}

export const ActionSetToken = async ({ commit }, payload) => {
  commit(types.SET_TOKEN, payload)
}

export const ActionSetToGetInstallments = async ({ commit }, payload) => {
  commit(types.SET_TO_GET_INSTALLMENTS, payload)
}

export const ActionSetInstallments = async ({ commit }, payload) => {
  commit(types.SET_INSTALLMENTS, payload)
}

export const ActionSetCustomer = async ({ commit }, payload) => {
  commit(types.SET_CUSTOMER, payload)
}

export const ActionSetAddress = async ({ commit }, payload) => {
  commit(types.SET_ADDRESS, payload)
}

export const ActionSetItems = async ({ commit }, payload) => {
  commit(types.SET_ITEMS, payload)
}

export const ActionRemoveItem = async ({ commit }, payload) => {
  await commit(types.REMOVE_ITEM, payload)
}

export const ActionAddItem = async ({ commit }, payload) => {
  commit(types.ADD_ITEM, payload)
}

export const ActionSetPayment = async ({ commit }, payload) => {
  commit(types.SET_PAYMENT, payload)
}

export const ActionSetOrdersGn = async ({ commit }, payload) => {
  commit(types.SET_ORDERS_GN, payload)
}
