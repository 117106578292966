import * as types from './mutations-types'

// eslint-disable-next-line import/prefer-default-export
export const ActionLoadSessionInfo = ({ commit }, payload) => {
  commit(types.SET_SESSION_INFO, payload)
}

export const ActionSetUser = ({ commit }, payload) => {
  // console.log(payload)
  commit(types.SET_USER, payload)
}
