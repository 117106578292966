export default {
  // Endpoints
  allCongressesEndpoint: 'congress',
  activiesCurrentEndpoint: 'events/per/date/now',
  activiesPreviousEndpoint: 'events/per/date/past',
  registerUserEventEndpoint: 'register/user/event',
  unregisterUserEventEndpoint: 'unregister/user/event',
  getUserEventsEndpoint: 'user/events',
  getEventsBySpeakerEndpoint: 'event/speaker/byspeaker',
  getCongressEndpoint: 'congress/get',
  getEventEndpoint: 'event/get',
  getEventSpeakerEndpoint: 'event/speaker/byevent',
  getDeviceId: 'verify-device-id',
  // total access
  congressAccess: 'total/access',

  // chat messages data
  sendMessage: 'send/message',
  getLiveMessages: 'get/message/live',
  getLiveInfo: 'congress/live',

  // share card
  getSpeakerEndpoint: 'speaker',
  getEventBlockEndpoint: 'event/block',
  getSpeakerBlocksNowEndpoint: 'events/blocks/speaker/now',
}
