/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-useless-escape */
import Vue from 'vue'

import VueTelInput from 'vue-tel-input'
import VueSweetalert2 from 'vue-sweetalert2'
import VueMask from 'v-mask'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueTimepicker from 'vue2-timepicker'
import 'vue-tel-input/dist/vue-tel-input.css'
import VueExcelXlsx from 'vue-excel-xlsx'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueFullscreen from 'vue-fullscreen'

// eslint-disable-next-line import/no-unresolved

// eslint-disable-next-line import/no-extraneous-dependencies
import 'sweetalert2/dist/sweetalert2.min.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import 'tributejs/dist/tribute.css'
import 'tributejs/dist/tribute.min'

import {
  ToastPlugin,
  ModalPlugin,
  BootstrapVue,
  IconsPlugin,
} from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import VueHtml2Canvas from 'vue-html2canvas'

// import { localize } from 'vee-validate'
// import pt_BR from 'vee-validate/dist/locale/pt_BR.json'
// import VeeValidate from 'vee-validate'
import router from './router'
import store from './store'
import App from './App.vue'

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import ICS from 'vue-ics'

// i18n
import i18n from '@/libs/i18n'
// localize('pt_BR', pt_BR)

// Auth
// import '@/libs/acl'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

import VueTheMask from 'vue-the-mask'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHatWizard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// chat tawk.to
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2'

library.add(faHatWizard)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(VueTheMask)

Vue.use(VueSweetalert2)

Vue.use(VueMoment, {
  moment,
})
Vue.use(vueVimeoPlayer)

Vue.use(VueHtml2Canvas)

Vue.use(VueMask)

Vue.use(VueTimepicker)

Vue.use(VueTelInput)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueExcelXlsx)

Vue.use(ICS)

Vue.use(VueFullscreen)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.use(VuePlyr, {
  plyr: {},
})

/* Vue.use(VeeValidate)
VeeValidate.extend('verify_password', {
  getMessage: () =>
    'The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)',
  validate: (value) => {
    // eslint-disable-next-line quotes
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    )
    return strongRegex.test(value)
  },
})
*/

Vue.mixin({
  methods: {
    filterGlobal(e, list, proprietyFilter) {
      const { value } = e.target
      const result = list.filter((item) =>
        item[proprietyFilter].toLowerCase().match(value.toLowerCase())
      )
      return Vue.observable(result)
    },
    filterBySpeaker(e, list) {
      const { value } = e.target
      const arrayEvents = []
      list.forEach((item) => {
        if (item.speakers.length > 0) {
          item.speakers.forEach((speaker) => {
            if (speaker.toLowerCase().match(value.toLowerCase())) {
              arrayEvents.push(item)
            }
          })
        }
      })
      return Vue.observable(arrayEvents)
    },
  },
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

Vue.use(TawkMessengerVue, {
  propertyId: 'property_id',
  widgetId: 'widget_id'
})
