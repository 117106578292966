import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async facebookLogin(...args) {
    const resp = await axios.post(this.jwtConfig.facebookAuthEndpoint, ...args)
    return resp
  }

  async googleLogin(...args) {
    const resp = await axios.post(this.jwtConfig.googleAuthEndpoint, ...args)
    return resp
  }

  async forgetPassword(...args) {
    const resp = await axios.post(
      this.jwtConfig.forgetPasswordEndpoint,
      ...args
    )
    return resp
  }

  async resetPassword(...args) {
    const resp = await axios.post(this.jwtConfig.resetPasswordEndpoint, ...args)
    return resp
  }

  async isLoggedIn(...args) {
    const resp = await axios.post(this.jwtConfig.isLoggedIn, ...args)

    return resp
  }
}
