export default {
  // Endpoints
  loginEndpoint: 'auth/login',
  registerEndpoint: 'auth/register',
  refreshEndpoint: 'jwt/refresh-token',
  forgetPasswordEndpoint: 'password/email',
  resetPasswordEndpoint: 'password/reset',
  isLoggedIn: 'is-logged-in',

  // Social Auth Endpoins
  facebookAuthEndpoint: 'auth/facebook',
  googleAuthEndpoint: 'auth/google',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
