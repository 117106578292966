// eslint-disable-next-line import/prefer-default-export
export const SET_ORDER = 'PAYMENT/SET_ORDER'
export const SET_TOKEN = 'PAYMENT/SET_TOKEN'
export const SET_INSTALLMENTS = 'PAYMENT/SET_INSTALLMENTS'
export const SET_CUSTOMER = 'PAYMENT/SET_CUSTOMER'
export const SET_ADDRESS = 'PAYMENT/SET_ADDRESS'
export const SET_ITEMS = 'PAYMENT/SET_ITEMS'
export const REMOVE_ITEM = 'PAYMENT/REMOVE_ITEM'
export const ADD_ITEM = 'PAYMENT/ADD_ITEM'
export const SET_PAYMENT = 'PAYMENT/SET_PAYMENT'
export const SET_TO_GET_INSTALLMENTS = 'PAYMENT/SET_TO_GET_INSTALLMENTS'
export const SET_ORDERS_GN = 'PAYMENT/SET_ORDERS_GN'
