import JwtService from '@core/auth/jwt/jwtService'
import config from './config'
import axios from '@axios'

export default class Services extends JwtService {
  jwtConfig = { ...config }

  async listAllMagazine(...args) {
    const resp = await axios.get(this.jwtConfig.listAllMagazine, {
      params: {
        ...args[0],
      },
    })
    return resp
  }
}
