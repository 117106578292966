const array = [
  {
    path: '/votacao',
    name: 'vote',
    component: () => import('./pages/index.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Votação'
    },
  },
]

export default array
