export default {
  congressAll: [],
  congress: [],
  event: {},
  userEvents: [],
  congressCurrentActivities: [],
  congressPreviousActivities: [],
  eventSpeaker: {
    password_vmix: false,
  },
  deviceId: null,
  liveMessages: [],
  speakerEvents: [],
  speaker: {},
  eventBlock: {},
  speakerBlocksNow: [],
}
