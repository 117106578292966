export default [
  {
    path: '/administrador/podcast',
    name: 'podcastAdministrador',
    component: () => import('./pages/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Podcasts',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
  {
    path: '/podcast/:slug/:id',
    name: 'Podcasts',
    component: () => import('./pages/vmix/List.vue'),
    meta: {
      layout: 'admin',
      requiresAuth: true,
      pageTitle: 'Live VMIX',
      breadcrumb: [
        // {
        //   text: 'Perfil',
        //   active: true,
        // },
      ],
    },
  },
]
