const array = [
  {
    path: '/sorteio',
    name: 'raffle',
    component: () => import('./pages/index.vue'),
    meta: {
      requiresAuth: true,
      pageTitle: 'Sorteio'
    },
  },
]

export default array
