// eslint-disable-next-line import/prefer-default-export
export const SET_CONGRESS_ALL = 'CONGRESS/SET_CONGRESS_ALL'
export const SET_CONGRESS = 'CONGRESS/SET_CONGRESS'
export const SET_CONGRESS_CURRENT_ACTIVITIES =
  'CONGRESS/SET_CONGRESS_CURRENT_ACTIVITIES'
export const SET_CONGRESS_PREVIOUS_ACTIVITIES =
  'CONGRESS/SET_CONGRESS_PREVIOUS_ACTIVITIES'
export const SET_EVENT = 'CONGRESS/SET_EVENT'
export const SET_EVENT_SPEAKER = 'CONGRESS/SET_EVENT_SPEAKER'
export const SET_DEVICE_ID = 'CONGRESS/SET_DEVICE_ID'
export const SET_LIVE_MESSAGES = 'CONGRESS/SET_LIVE_MESSAGES'
export const SET_USER_EVENTS = 'CONGRESS/SET_USER_EVENTS'
export const SET_SPEAKER_EVENTS = 'CONGRESS/SET_SPEAKER_EVENTS'
export const SET_SPEAKER = 'CONGRESS/SET_SPEAKER'
export const SET_EVENT_BLOCK = 'CONGRESS/SET_EVENT_BLOCK'
export const SET_SPEAKER_BLOCKS_NOW = 'CONGRESS/SET_SPEAKER_BLOCKS_NOW'
