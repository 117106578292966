export default [
  {
    path: '/classificacao',
    name: 'ranking',
    component: () => import('./pages/Ranking.vue'),
    meta: {
      layout: 'vertical',
      requiresAuth: true,
      pageTitle: 'Classificação geral',
      breadcrumb: [],
    },
  },
]
