// import Vue from 'vue'
// axios
import axios from 'axios'

const token = localStorage.getItem('accessToken')
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.VUE_APP_API,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
})

// Vue.prototype.$http = axiosIns
// Vue.config.productionTip = false

export default axiosIns
